export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login/:id?',
    name: 'login',
    component: () => import('@/views/ClientLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('@/views/AdminLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/resetpassword/:token',
    component: () => import('@/views/client/reset_password/ResetPassword'),
    name: 'reset-password',
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/forgotpassword',
    component: () => import('@/views/client/forgot_password/ForgotPassword'),
    name: 'forgot-password',
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
]

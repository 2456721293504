import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Composition API
Vue.use(VueCompositionAPI)

/*import i18n from '@/libs/i18n'*/
import router from './router'
import store from './store'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import * as VueGoogleMaps from 'vue2-google-maps';

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import viewer from 'v-viewer'
import VueClipboard from 'vue-clipboard2'

import CKEditor from 'ckeditor4-vue';

import 'v-autocomplete/dist/v-autocomplete.css'
import Autocomplete from 'v-autocomplete'
Vue.use(Autocomplete)

Vue.use( CKEditor );
 
Vue.use(VueClipboard)

Vue.use(viewer);

Vue.use(VueGoogleMaps, {
  
  load: {
    key: 'AIzaSyAKeDjawjCEvrOve8mqI__tr_xA7_zN1f4',
    libraries: 'places',
  },
  installComponents: true
})

import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component('GmapCluster', GmapCluster);

//import moment from 'moment'
var moment = require('moment-timezone');

import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);



// import 'cropperjs/dist/cropper.css';



// Axios Mock Adapter


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueAxios, axios)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

window.Swal = Swal;

Vue.config.productionTip = false

Vue.filter('capitalize', function (value) {
 if (!value) return 'N/A'
 value = value.replace(/_/g,' ');
 value = value.toString()
 /*return value.toUpperCase()*/
 return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('dateTime', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).tz('Asia/Singapore').format('DD-MM-YYYY HH:mm A')
		return value
	})

Vue.filter('unixDateTime', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('DD-MM-YYYY HH:mm A')
		return value
	})

Vue.filter('dateTime2', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('DD-MM-YYYY HH:mm A')
		return value
	})

Vue.filter('timeHM', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('HH:mm')
		return value
	})

Vue.filter('date', function (value) {
	if (!value) return 'N/A'

		value = moment(value).tz('Asia/Singapore').format('DD-MM-YYYY HH:mm A')
		return value
	})

Vue.filter('dateRangePicker', function (value) {
	if (!value) return ''

		value = moment(value).format('DD-MM-YYYY HH:mm A')
		return value
	})

Vue.filter('currency', function (value) {
	if (!value) return 0.00
	 	return parseFloat(value).toFixed(2)
	})

Vue.filter('trim', function (value) {
	value = value.toString()
    if (value.length < 200) {
        return value;
    } else {
        return value.substring(0, 200) + '...';
    }
})

Vue.filter('time', function (value) {
	if (!value) return ''
	
		value = value.substring(0, value.length - 3);
		return value
	})

Vue.filter('timeFormat', function (value) {
	if (!value) return ''
	
		value = moment(value,'HH:mm').format('hh:mm A')
		return value
	})


Vue.filter('eventCal', function (arg) {
	
	if (arg.event._def.extendedProps.durations_in_day == null){ 
		return arg.event.title
	}
	else if (arg.event._def.extendedProps.durations_in_day > 1) {
		return arg.event.title;
	}
	else if(arg.event._def.extendedProps.durations_in_day == 1 && arg.event.title.length >= 20){

		return arg.event.title.substring(0, 20) + '...' ;
	}
	else if(arg.event._def.extendedProps.durations_in_day == 1 && arg.event.title.length < 20){
		return arg.event.title;
	}else{
		return arg.event.title;
	}
})

Vue.filter('dateTimeSec', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('DD MMM YYYY HH:mm:ss')
		return value
	})

Vue.filter('crewArray', function (value) {
	if (value.length > 0) {

		var crew = [];

	    value.forEach(i => {
	      crew.push(i.full_name);
	    })

	    return crew.join(', ');

	}else{
		return 'N/A';
	}
	
})

Vue.filter('shiftArray', function (value) {
	if (value.length > 0) {

		var shift = [];

	    value.forEach(i => {
	      shift.push(i.shift);
	    })

	    return shift.join(', ');

	}else{
		return 'N/A';
	}
	
})

Vue.filter('groupArray', function (value) {
	if (value.length > 0) {
		var crew = [];

		value.forEach(i => {
			crew.push(i);
		})
		return crew.join(', ');
	}else{
		return 'N/A';
	}
})

Vue.filter('removeUnderCapitalize',function(value){
	if (value) {
		value = value.replace(/_/g, ' ');

		value = value.split(' ');

		var arr = [];
		value.forEach(item => {
			arr.push(item.charAt(0).toUpperCase() + item.slice(1))
		})

		return arr.join(' ');
	}else{
		return 'N/A';
	}
})

Vue.mixin({
	methods: {
		checkPermission: function (route, label) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent
					if(menu.permissions){
						if(menu.route == route){
							menu.permissions.forEach(access => {
								if(access.label == label){
									flag = access.access;
								}
							});
						}
					}
				// for menu with children
					if(menu.children){
						menu.children.forEach(child => {
							if(child.route == route){
								child.permissions.forEach(access => {
									if(access.label == label){
										flag = access.access;
									}
								});
							}
						});
					}
				});
			}
			return flag;
			// return true;
		},

		showHideMenu: function (title, type) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent
					if(menu.title == title && type == 'parent'){
						flag = menu.access;
					}
				// for menu with children
					else if (menu.children && type == 'child'){
						menu.children.forEach(child => {
							if(child.title == title){
								flag = child.access;
							}
						});
					}
				});
			}
			return flag;
			// return true;
		},

		showHideMenuPermission: function (title, label) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent
					if(menu.permissions){
						if(menu.title == title){
							menu.permissions.forEach(access => {
								if(access.label == label){
									flag = access.access;
								}
							});
						}
					}
				// for menu with children
					if(menu.children){
						menu.children.forEach(child => {
							if(child.title == title){
								child.permissions.forEach(access => {
									if(access.label == label){
										flag = access.access;
									}
								});
							}
						});
					}
				});
			}
			return flag;
		},

		setDefaultPage: function (route) {
			var flag = false;
			var route = null;
			return route;
		},

		successAlert: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'success',
				title    : this.$store.getters.getResults.message,
				timer    : 1500,
				showConfirmButton : false
			});
		},
		
		errorAlert: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'error',
				title    : this.$store.getters.getErrors,
				timer    : 1500,
				showConfirmButton : false
			});
		},

		errorAlertCF: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'error',
				title    : this.$store.getters.getErrors
			});
		},

		decisionAlert: function(msg){
			return Swal.fire({
				title              : 'Please Confirm',
				text               : msg,
				icon               : 'warning',
				position           : 'center',
				showCancelButton   : true,
				confirmButtonColor : '#3085d6',
				cancelButtonColor  : '#d33',
				confirmButtonText  : 'Yes',
				cancelButtonText   : 'No'
			});
		},

		breadCrumList:function(route_name,label){
			var menus = this.$store.getters.currentUser.menu_permission;
			var b_array = [];

			for (var i = 0; i < menus.length; i++) {
				if(typeof menus[i].route != 'undefined' && menus[i].route == route_name){

					b_array.push({
			          text: menus[i].title,
			          to:menus[i].route
			        });

			        return b_array;

				}else if (typeof menus[i].route == 'undefined' && menus[i].children.length > 0){
					
					for (var j = 0; j < menus[i].children.length; j++) {
						
						if(menus[i].children[j].route == route_name) {
							
							b_array.push({
					          text: menus[i].title,
					          to:null
					        });

					        b_array.push({
					          text: menus[i].children[j].title,
					          to:menus[i].children[j].route
					        });
					        
					        return b_array;
			        	
						}
					}
				}
			}

			
			
		}
	}
})


new Vue({
  router,
  store,
  /*i18n,*/
  render: h => h(App),
}).$mount('#app')

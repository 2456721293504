import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// Routes
import admin from './routes/admin'
import website from './routes/website'
import client from './routes/client'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...website,
    ...admin,
    ...client,
    
   /*{
      path: '*',
      redirect: 'login',
    },*/
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    localStorage.setItem('routeCFCheck','no')
    next('/login');
  }else if(store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.currentUser.account_type === 'Admin'){
    localStorage.setItem('routeCFCheck','no')
    next('/admin/manage-order');
  }else if(store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.currentUser.account_type ==='Customer'){
    localStorage.setItem('routeCFCheck','no')
    next('/client/place-order');
  }else if(to.meta.showFor === 'Admin' && store.getters.currentUser.account_type === 'Customer') {
    next('/client/dashboard');
  }
    if (localStorage.getItem('routeCFCheck') == 'yes') {
        
        Swal.fire({
          title: 'Please Confirm',
          text: "Do you want to Go back without saving your report?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
            if(result.isConfirmed == true){
              localStorage.setItem('routeCFCheck','no');
              return next();
            }
        })
        
    }else{
        localStorage.setItem('routeCFCheck','no')
        return next();
      
    }
    
    
  } 
)

export default router

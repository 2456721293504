export default [
  {
    path: '/admin/manage-order',
    name: 'manage-order',
    route: 'manage-order',
    component: () => import('@/views/admin/manage-order'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/order-summary',
    name: 'order-summary',
    route: 'order-summary',
    component: () => import('@/views/admin/order_summary/Summary'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/order-database',
    name: 'order-database',
    route: 'order-database',
    component: () => import('@/views/admin/orders_database/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    route: 'admin-dashboard',
    component: () => import('@/views/admin/dashboard'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/user-database',
    name: 'user-database',
    route: 'user-database',
    component: () => import('@/views/admin/user_database/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/change-password',
    name: 'change-password',
    route: 'change-password',
    component: () => import('@/views/admin/change_password/ChangePassword'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Admin' }
  },
  {
    path: '/admin/item-database',
    name: 'item-database',
    route: 'item-database',
    component: () => import('@/views/admin/item_database/Report'),
    meta: { requiresAuth: true, hideForAuth: false, showFor: 'Admin'}
  },
]

export default [
  {
    path: '/client/dashboard',
    name: 'client-dashboard',
    route: 'client-dashboard',
    component: () => import('@/views/client/dashboard'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Customer', accountStatus:'approved' }
  },
  {
    path: '/client/orders-database',
    name: 'client-orders-database',
    route: 'client-orders-database',
    component: () => import('@/views/client/orders_database/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Customer' }
  },
  {
    path: '/client/place-order',
    name: 'place-order',
    route: 'place-order',
    component: () => import('@/views/client/place_order/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Customer' }
  },
  {
    path: '/client/confirm-order',
    name: 'confirm-order',
    route: 'confirm-order',
    component: () => import('@/views/client/confirm_order'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Customer' }
  },
  {
    path: '/client/change-password',
    name: 'client-change-password',
    route: 'client-change-password',
    component: () => import('@/views/client/change_password/ChangePassword'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'Customer' }
  },
]
